
function header() {
    var $window = $(window);
    
    if ($window.scrollTop() >= "200") {
        $("header").addClass("fixed");
    }else{
        $("header").removeClass("fixed");
    }
}

function menu() {
    var $window = $(window),
        $avagamundo = $("#avagamundo"),
        $produtos = $("#produtos"),
        $lojas = $("#lojas"),
        $instamundo = $("#instamundo"),
        $contato = $("#contato");

    if ($window.scrollTop() < $avagamundo.offset().top) {
        $("header nav ul li a").removeClass("active");
        $("header nav ul li a[href='#home']").addClass("active");
    }

    if ($window.scrollTop() >= $avagamundo.offset().top - 5 && $window.scrollTop() < $produtos.offset().top) {
        $("header nav ul li a").removeClass("active");
        $("header nav ul li a[href='#avagamundo']").addClass("active");
    }

    if ($window.scrollTop() >= $produtos.offset().top - 5 && $window.scrollTop() < $lojas.offset().top) {
        $("header nav ul li a").removeClass("active");
        $("header nav ul li a[href='#produtos']").addClass("active");
    }

    if ($window.scrollTop() >= $lojas.offset().top - 5 && $window.scrollTop() < $instamundo.offset().top) {
        $("header nav ul li a").removeClass("active");
        $("header nav ul li a[href='#lojas']").addClass("active");
    }

    if ($window.scrollTop() >= $instamundo.offset().top - 5 && $window.scrollTop() < $contato.offset().top) {
        $("header nav ul li a").removeClass("active");
        $("header nav ul li a[href='#instamundo']").addClass("active");
    }

    if ($window.scrollTop() >= $contato.offset().top - 5) {
        $("header nav ul li a").removeClass("active");
        $("header nav ul li a[href='#contato']").addClass("active");
    }
}

function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

function viewProdutos() {
    var $window = $(window);
    if ($window.width() > 768) {
        $('#produtos > div.owl-carousel').owlCarousel('destroy');
        if ($("#produtos > div").find(".grid-sizer").length <= 0) {
            $("#produtos > div").prepend('<span class="grid-sizer"></span>');
        }
        $("#produtos > div").addClass("masonry").removeClass("owl-carousel").css("position", "relative").find(".grid-item").css("position", "absolute");

        fancyboxProdutos ();
    }else{
        carouselProdutos();
        $("#produtos > div.owl-carousel a.grid-item").attr('rel', 'gallery').fancybox({live: false});
        //remove click handler
        $('#produtos > div.owl-carousel a.grid-item').off("click.fb-start");

        $("#produtos > div.owl-carousel a.grid-item").on("click", function (e) {
            e.preventDefault();
        });
    }
}

function fancyboxProdutos () {
    $("#produtos > div.masonry a.grid-item").fancybox({
		openEffect : 'elastic',
        openSpeed  : 150,
        closeEffect : 'elastic',
        closeSpeed  : 150,
        padding : 0,
        
        wrapCSS    : 'fancybox-custom',

        helpers : {
            title : {
                type : 'inside'
            },
            
            overlay : {
                css : {
                    'background' : 'rgba(0,0,0,0.85)'
                }
            }
        },

        afterLoad : function() {
            this.title = 'Imagem ' + (this.index + 1) + ' de ' + this.group.length + (this.title ? ' - ' + this.title : '');
        },
        tpl: { 
            next: '<a title="Próxima" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>', 
            previous: '<a title="Anterior" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>',
            closeBtn: '<a title="Fechar" class="fancybox-item fancybox-close" href="javascript:;"></a>'
        }
		// 'overlayShow'	:	false
    });
}

function carouselProdutos() {
    $("#produtos > div").removeClass("masonry").addClass("owl-carousel").attr("style", "").find(".grid-item").attr("style", "");
    $("#produtos > div").find(".grid-sizer").remove();
    /**
     * Carrosel HOME
     */
    $('#produtos > div.owl-carousel').owlCarousel({
        loop:false,
        responsiveClass:true,
        slideBy: 1,
        items:1,
        autoHeight: true,
        nav:true,
        dots:false,
        onChanged: callback
    });
}

function callback(event) {
    // Provided by the core
    var element   = event.target;         // DOM element, in this example .owl-carousel
    var name      = event.type;           // Name of the event, in this example dragged
    var namespace = event.namespace;      // Namespace of the event, in this example owl.carousel
    var items     = event.item.count;     // Number of items
    var item      = event.item.index;     // Position of the current item
    // Provided by the navigation plugin
    var pages     = event.page.count;     // Number of pages
    var page      = event.page.index;     // Position of the current page
    var size      = event.page.size;      // Number of items per page

    $("#produtos > p").html("Imagem " + (item+1) + " de " + items);
}

function mansoryProdutos() {
    $('#produtos > div.masonry').masonry({
        columnWidth: '.grid-sizer',
        itemSelector: '.grid-item',
        percentPosition: true
    });
}