$(window).on('load', function () {
    $("#loading").hide();

    // $('nav ul li a').bind('click',function(rolar) {
    //     rolar.preventDefault();
    //     var ancora = $(this);
    //     $('html, body').stop().animate({
    //         scrollTop: ($(ancora.attr('href')).offset().top)
    //     }, 1200, 'easeInOutExpo');

    //     $("header nav").removeClass("abreMenu");
    //     $("body").removeClass("overflowHidden");
    // });
    
});

$(window).resize(function () {
    
});

$(document).on('ready', function () {
    // alturaElement(".list-products > .itens.featured > article > section");
});

$(window).scroll(function() {
});