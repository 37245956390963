$(document).ready(function() {
    showHash();
    videoFluid();
});

$(window).on('load', function () {
    showHash();
    videoFluid();
});

$(window).resize(function () { 
    videoFluid();
});

/**
 * Show hash
 */
function showHash () {
    var x = location.hash;
    
    if (x) {
        setTimeout(function() {
            $("nav ul li a[href='"+x+"']").click();
        },10);
        
        if (x=="#contato" || x=="#revenda" || x=="#trabalhe-conosco") {
            setTimeout(function() {
                $("nav ul li a[href='#contato']").click();
                $("#contato ul li [href='"+x+"']").click();
            },10);
        }
        
        // var body = $("html, body");
	    // body.stop().animate({scrollTop:0}, '500', 'swing');

        
    }
}

/**
 * Habilitar button do form ao selecionar o captcha
 * @param string token 
 */
function recaptchaCallback(token) {
    $(".g-recaptcha-response").each(function (param) {
        if (token == $(this).val()) {
            var $text = $(this),
                $buttom = $text.closest("form").find("button");

            $buttom.prop("disabled", false);

            var clearRecaptcha = setInterval(function() {
                if ($text.val() == "") {
                    $buttom.prop("disabled", true);
                    clearInterval(clearRecaptcha);
                }
            }, 121000);
        }
    });
}

/**
 * Redimensionamento dos vídeos
 */
function videoFluid() {
    $(".video-fluid iframe").each(function (index, element) {

        var $iframe = $(this),
            w = $iframe.width(),
            h = (w * 315)/560;
        
        $(this).height(h);
    });
}

/**
 * Definir altura minima para os elementos de acordo com o maior
 * 
 * @param string element
 */
function alturaElement (element) {
    altura = 0;
    $.each($(element), function() {
        if ($(this).height() > altura) {
            altura = $(this).height();
        }
    });
    $(element).css("min-height", altura+"px");
}